<template>
<svg viewBox="0 0 48 96" focusable="false">
  <path d="M23.9932 8.91386L23.4688 10.6953V62.3843L23.9932 62.9075L47.9862 48.725L23.9932 8.91386Z" fill="#716CB1">
  </path>
  <path d="M23.9936 8.91386L0 48.725L23.9936 62.9075V37.8191V8.91386Z" fill="#8588C6"></path>
  <path d="M23.9909 67.4523L23.6953 67.8128V86.2251L23.9909 87.088L47.9985 53.2772L23.9909 67.4523Z" fill="#585799">
  </path>
  <path d="M23.9936 87.088V67.4523L0 53.2772L23.9936 87.088Z" fill="#8588C6"></path>
  <path d="M23.9941 62.9066L47.9871 48.7242L23.9941 37.8183V62.9066Z" fill="#585799"></path>
  <path d="M0 48.7242L23.9936 62.9066V37.8183L0 48.7242Z" fill="#716CB1"></path>
</svg>
</template>

<style scoped lang='scss'>
@import '../styles/mixins.scss';
  svg {
    width: 32px;
    height: 32px;
    object-fit: contain;
    @include breakpoint(mobile) {
      width: 24px;
      height: 24px;
    }
  }
</style>