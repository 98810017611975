<template>
  <Header/>
  <Footer/>
  <AppToastMessage/>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import AppToastMessage from '@/components/AppToastMessage'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    AppToastMessage
  },
  mounted() {
    document.title = "NFT | Going to Space";
  }
  
}
</script>

<style lang="scss">
@import url("https://use.typekit.net/tcu6pof.css");
@import './styles/mixins.scss';

#app {
  font-family: 'industry', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

div,
footer,
section,
article {
  position: relative;
  box-sizing: border-box;
}

section {
  background-color: #000000;
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  z-index: 2;

  @include breakpoint(tablet) {
    padding: 0 40px;
  }

  @include breakpoint(mobile) {
    padding: 0 30px;
  }
}

h1, h2 {
  font-family: 'industry-inc-in-n-out', sans-serif;
  text-transform: uppercase;
}

h1 {
  color: #ffffff;
  font-size: 96px;
  line-height: 1;
  margin: 0 0 24px;

  @include breakpoint(laptop) {
    font-size: 80px;
    margin-bottom: 32px;
  }
}

h2 {
  color: #ffffff;
  font-size: 64px;
  line-height: 1.1;
  margin: 0 0 56px;

  @include breakpoint(laptop) {
    font-size: 56px;
  }

  @include breakpoint(tablet) {
    font-size: 40px;
  }

  @include breakpoint(mobile) {
    margin-bottom: 32px;
  }
}

h3 {
  color: #ffffff;
  font-size: 56px;
  font-family: 'industry', sans-serif;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
}

h4 {
  color: #ffffff;
  font-size: 32px;
  font-family: 'industry', sans-serif;
  font-weight: 600;
  line-height: 1.3;
  text-transform: capitalize;
  margin: 0 0 40px;

  @include breakpoint(laptop) {
    font-size: 32px;
  }

  @include breakpoint(mobile) {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

h5 {
  color: #ffffff;
  font-size: 32px;
  font-family: 'industry', sans-serif;
  font-weight: 600;
  line-height: 1.1;
  text-transform: capitalize;
}

h6 {
  color: #cccccc;
  font-size: 16px;
  font-family: 'industry', sans-serif;
  font-weight: 600;
  line-height: 1.1;
  text-transform: uppercase; 
  margin: 0 0 24px;
}

.headline {
  color: #36DDB9;
  font-size: 18px;
  font-weight: 700;
}

p {
  color: #ffffff;
  font-size: 18px;
  line-height: 1.6;
  margin: 0 0 24px;

  span {
    color: #A9B3D6;
  }
}

p > a {
  color: inherit;
}

.readmore {
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

blockquote {
  margin: 0;

  p {
    font-size: 16px;
    font-style: italic;
    margin: 0 0 16px;
  }
}

ul {
  margin: 0 0 24px;
  padding-left: 16px;
}

li {
  color: #cccccc;
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 32px;
}

li:not(:last-child) {
  margin-bottom: 16px;
}

.button {
  color: #ffffff;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  padding: 0 40px;
  display: inline-block;
  position: relative;
  background-color: #6A00FF;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  font-family: 'industry', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  transition: box-shadow 300ms ease-out;

  &:hover {
    box-shadow: 0 0 40px 0px rgba(180,0,255,0.75);
    -webkit-box-shadow: 0 0 40px 0px rgba(180,0,255,0.75);
    -moz-box-shadow: 0 0 40px 0px rgba(180,0,255,0.75);
  }
  &.ghost {
    background-color: transparent;
    border: 2px solid #6A00FF;
  }
  &.disabled {
    filter: saturate(0.15);
    pointer-events: none;
  }
}


.bg-img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;
  z-index: 1;
}
</style>
