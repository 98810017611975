import { createStore } from 'vuex'
import { getRandomId } from '@/utils';

export default createStore({
  state: {
    toastMessages: []
  },
  mutations: {
    SEND_TOAST_MESSAGE (state, { message, type }) {
      state.toastMessages.push({
        message,
        type,
        id: getRandomId('toast')
      })
    },
    DELETE_TOAST_MESSAGE (state, { id }) {
      state.toastMessages = state.toastMessages.filter(msg => msg.id !== id)
    }
  },
  getters: {
    toastMessages: state => state.toastMessages
  },
  actions: {}
});
