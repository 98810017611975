<template>
  <svg
    :class="name"
    class="app-icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
  >
    <defs>
      <title>{{ name }} icon</title>
    </defs>

    <g v-if="/image/.test(name)">
      <polygon class="stroke" points="6 25 10 18 14 21 20 14 26 25 6 25" />
      <circle class="stroke" cx="12.5" cy="10.5" r="2.5" />
      <rect class="stroke" x="2" y="2" width="28" height="28" />
    </g>

    <g v-else-if="/error/.test(name)">
      <path
        class="fill"
        d="M31.707,9.08,22.92.293A1,1,0,0,0,22.213,0H9.787A1,1,0,0,0,9.08.293L.293,9.08A1,1,0,0,0,0,9.787V22.213a1,1,0,0,0,.293.707L9.08,31.707A1,1,0,0,0,9.787,32H22.213a1,1,0,0,0,.707-.293l8.787-8.787A1,1,0,0,0,32,22.213V9.787A1,1,0,0,0,31.707,9.08ZM17.929,7,17,19H15L14.071,7ZM16,25a2,2,0,1,1,2-2A2,2,0,0,1,16,25Z"
      />
    </g>

    <g v-else-if="/check/.test(name)">
      <polyline class="stroke" points="2 16 11 25 30 6" />
    </g>

    <g v-else-if="/arrow-out/.test(name)">
      <line class="stroke" x1="2" y1="30" x2="30" y2="2" />
      <polyline class="stroke" points="18 2 30 2 30 14" />
    </g>

    <g v-else-if="/warning/.test(name)">
      <path
        class="fill"
        d="M31.633,26.522,18.683,2.6a3.053,3.053,0,0,0-5.366,0L.367,26.522A3,3,0,0,0,.43,29.514,3.024,3.024,0,0,0,3.05,31h25.9a3.038,3.038,0,0,0,2.683-4.478ZM17.848,11l-.714,10h-2L14.42,11ZM16.134,27a1.778,1.778,0,1,1,1.778-1.778A1.778,1.778,0,0,1,16.134,27Z"
      />
    </g>

    <g v-else-if="/close/.test(name)">
      <path
        class="fill"
        d="M16,0A16,16,0,1,0,32,16,16.047,16.047,0,0,0,16,0Zm7.414,22L22,23.414l-6-6-6,6L8.586,22l6-6-6-6L10,8.586l6,6,6-6L23.414,10l-6,6Z"/>
    </g>

    <g v-else-if="/menu/.test(name)">
      <line class="stroke" x1="2" y1="16" x2="30" y2="16" />
      <line class="stroke" x1="2" y1="7" x2="30" y2="7" />
      <line class="stroke" x1="2" y1="25" x2="30" y2="25" />
    </g>

    <g v-else-if="/info/.test(name)">
      <path
        class='fill'
        d="M16,0C7.178,0,0,7.178,0,16s7.178,16,16,16s16-7.178,16-16S24.822,0,16,0z M17.929,7L17,19h-2L14.071,7 H17.929z M16,25c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2c1.105,0,2,0.895,2,2C18,24.105,17.105,25,16,25z"
      />
    </g>

    <g v-else-if="/facebook/.test(name)">
      <path
        class="fill"
        d="M12.46218,31V18H8v-6h4.46218V7.80998C12.46218,3.24577,15.35166,1,19.42325,1 c1.95033,0,3.62655,0.1452,4.11504,0.21011v4.76987l-2.82387,0.00128C18.50007,5.98127,18,7.0335,18,8.57757V12h6l-2,6h-4v13 H12.46218z"
      ></path>
    </g>

    <g v-else-if="/twitter/.test(name)">
      <path
        class="fill"
        d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"
      ></path>
    </g>

    <g v-else-if="/instagram/.test(name)">
      <path
        class="fill"
        d="M16,3.7c4,0,4.479.015,6.061.087a6.426,6.426,0,0,1,4.51,1.639,6.426,6.426,0,0,1,1.639,4.51C28.282,11.521,28.3,12,28.3,16s-.015,4.479-.087,6.061a6.426,6.426,0,0,1-1.639,4.51,6.425,6.425,0,0,1-4.51,1.639c-1.582.072-2.056.087-6.061.087s-4.479-.015-6.061-.087a6.426,6.426,0,0,1-4.51-1.639,6.425,6.425,0,0,1-1.639-4.51C3.718,20.479,3.7,20.005,3.7,16s.015-4.479.087-6.061a6.426,6.426,0,0,1,1.639-4.51A6.426,6.426,0,0,1,9.939,3.79C11.521,3.718,12,3.7,16,3.7M16,1c-4.073,0-4.584.017-6.185.09a8.974,8.974,0,0,0-6.3,2.427,8.971,8.971,0,0,0-2.427,6.3C1.017,11.416,1,11.927,1,16s.017,4.584.09,6.185a8.974,8.974,0,0,0,2.427,6.3,8.971,8.971,0,0,0,6.3,2.427c1.6.073,2.112.09,6.185.09s4.584-.017,6.185-.09a8.974,8.974,0,0,0,6.3-2.427,8.971,8.971,0,0,0,2.427-6.3c.073-1.6.09-2.112.09-6.185s-.017-4.584-.09-6.185a8.974,8.974,0,0,0-2.427-6.3,8.971,8.971,0,0,0-6.3-2.427C20.584,1.017,20.073,1,16,1Z"
        fill="#000000"
      ></path>
      <path
        class="fill"
        d="M16,8.3A7.7,7.7,0,1,0,23.7,16,7.7,7.7,0,0,0,16,8.3ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"
      ></path>
      <circle class="fill" cx="24.007" cy="7.993" r="1.8"></circle>
    </g>

    <g v-else-if="/tiktok/.test(name)">
      <path
        class="fill"
        d="M13.586,11.917v5.5a4.784,4.784,0,0,0-6,4.431,4.324,4.324,0,0,0,4.623,4.589c3.416,0,4.623-2.4,4.623-4.651V0h5.438c.925,5.753,3.8,7.088,7.654,7.705v5.512s-4.572-.325-7.5-2.533V21.573C22.421,26.5,19.5,32,12.25,32,5.522,32,2.08,26.3,2.08,21.522A10.02,10.02,0,0,1,13.586,11.917Z"
      />
    </g>

    <g v-else-if="/email/.test(name)">
      <path
        class="fill"
        d="M17.412,20.647a3,3,0,0,1-2.822,0L0,12.867V25a3.957,3.957,0,0,0,4,4H28a3.957,3.957,0,0,0,4-4V12.867Z"
        fill="#000000"
      ></path>
      <path
        class="fill"
        d="M28,3H4A3.957,3.957,0,0,0,0,7v3a1,1,0,0,0,.529.883l15,8a1.007,1.007,0,0,0,.942,0l15-8A1,1,0,0,0,32,10V7A3.957,3.957,0,0,0,28,3Z"
      ></path>
    </g>

    <g v-else-if="/photo/.test(name)">
      <path
        class="stroke"
        d="M4,29H28a3,3,0,0,0,3-3V10a3,3,0,0,0-3-3H23L20,2H12L9,7H4a3,3,0,0,0-3,3V26A3,3,0,0,0,4,29Z"
      />
      <circle class="stroke" cx="16" cy="18" r="7" />
    </g>

    <g v-else-if="/trash/.test(name)">
      <path
        class="stroke"
        d="M20.58402,20.00003 c-0.24302,0.55739-0.59251,1.07953-1.04849,1.53551c-1.95262,1.95262-5.11845,1.95262-7.07107,0s-1.95262-5.11845,0-7.07107 s5.11845-1.95262,7.07107,0"
      />
      <polygon class="stroke" points="18,16 21,16 21,13" />
      <polyline class="stroke" points="5,5 7,31 25,31 27,5" />
      <line class="stroke" x1="2" y1="5" x2="30" y2="5" />
      <polyline class="stroke" points="12,5 12,1 20,1 20,5" />
    </g>

    <g v-else>
      <rect class="stroke" x="0" y="0" width="32" height="32" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "app-icon",
  props: {
    name: {
      type: String,
      default: 'info'
    }
  }
};
</script>

<style lang="scss">

.app-icon {
  width: 16px;
  height: 16px;
  stroke-width: 1;
  stroke: inherit;
  fill: inherit;
  overflow: visible;
  outline: none;
  .fill:not(.stroke) {
    fill: inherit;
    stroke: none;
  }
  .stroke:not(.fill) {
    fill: none;
    stroke: inherit;
  }
  &:focus {
    .fill {
      fill: inherit;
    }
    .stroke {
      stroke: inherit;
    }
  }
}

</style>