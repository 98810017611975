<template>
  <section class="bitbasel-collection">
    <div class="container">

      <div class="featured-collection normal" id="astronaut-dr-sian-proctor">
        <div class="col featured-text">
          <div class="featured-meta">
            <div class="portrait">
              <img :src="portraitSian" alt="Astronaut Dr Sian Proctor">
            </div>
            <h4>Astronaut Dr. Sian Proctor</h4>
          </div>
          <p><span>Dr Sian Proctor</span> is a geoscientist, explorer, space artist, poet, and astronaut who recently became the first black female pilot of an orbital spacecraft. We are proud to share Dr. Proctor’s incredible and exclusive genesis NFT Art Collection with our Going to Space community.</p>
          <h6 class="headline">NFT Description</h6>
          <p>"Seeker" - Edition: 100 Pieces</p>
          <p>Looped Animation NFT from Sian Proctor's original painting set to music by Test Shot Starfish (2563x2050 H264) animated by Cory Shaw.</p>
        </div>

        <div class="col featured-image">
          <div class="featured-artwork is-video">
            <iframe
              src="https://player.vimeo.com/video/650738266?h=0acffe2e21&amp;autoplay=1&amp;autopause=false&amp;loop=1&amp;muted=1&amp;?background=1"
              width="640"
              height="512"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
            />
            <i class="border-radius"></i>
          </div>
          <PriceInput
            :onMint='onMint'
            :price='0.2'
            :totalRemaining='this.remainingSian'
            :minter='0'
            v-bind:showRemains='this.showRemains'
          />
        </div>
      </div>

      <div class="featured-collection reverse" id="astronaut-ron-garan">
        <div class="col featured-text">
          <div class="featured-meta">
            <div class="portrait">
              <img :src="portraitRon" alt="Astronaut Ron Garan">
            </div>
            <h4>Astronaut Ron Garan</h4>
          </div>
          <p>Ron Garan is a former NASA astronaut that traveled 71,075,867 miles in 2,842 orbits of our planet during more than 178 days in space and 27 hours and 3 minutes of EVA during four spacewalks. Ron flew on both the US Space Shuttle and the Russian Soyuz spacecraft. He also participated in the joint NASA-NOAA, NEEMO-9 mission, an exploration research mission held in Aquarius, the world’s only undersea research laboratory.</p>
          <h6 class="headline">NFT Description</h6>
          <p>Perspective (aka Space Selfie) is a composition about perspective Edition: 1 of 1</p>
          <blockquote>
            <p v-if="!readMoreActivated">{{longText.slice(0, 287)}}</p>
            <div class="readmore" v-if="!readMoreActivated" @click="activateReadMore">Read more</div>
            <p v-if="readMoreActivated" v-html="longText"></p>
          </blockquote>

          <!-- <blockquote>
            <p>“There usually are two ways to define the word perspective. It may refer to the rendering or interpretation of a three-dimensional object on a two-dimensional plane, or it may refer to an attitude toward something, a point of view. Both of these definitions come into play in this piece.</p>
            <p>Historically, for the most part, our perspective has been two-dimensional. Although we know that the world is not flat, a true perspective on a three-dimensional, interrelated reality is usually beyond our immediate awareness.</p>
            <p>This piece was inspired by a photograph I took during a spacewalk on the STS-124 mission. In some respects, this work can be seen as a self-portrait. My reflection is depicted in the helmet visor of my spacewalk partner Astronaut Mike Fossum. In the reflection I can be seen taking the photograph that this work is based on.</p>
            <p>As opposed to establishing a focal point with surrounding areas leading the viewer’s eye to that focal point, in this piece great detail can be found throughout. This was done to communicate the sometimes overwhelming experience of a spacewalk where the participant is bombarded with compelling imagery competing for the viewer’s attention. The detail that is found throughout is also meant to acknowledge and applaud the great detail that must be present during all aspects of a space mission to maximize the chances of a safe mission.</p>
            <p>The setting of the piece occurs moments before passing into orbital night. My goal in the piece was also to contrast the technical and visually overwhelming experience of a spacewalk with the tranquil beauty of our planet as we approach one of our 16 daily sunsets.”</p>
          </blockquote> -->
        </div>

        <div class="col featured-image">
          <div class="featured-artwork is-video">
            <!-- <img :src="artRon1" alt="Astronaut Ron Garan Artwork"> -->
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/667910604?h=fc329a2fda&amp;autoplay=1&amp;autopause=false&amp;loop=1&amp;muted=1&amp;?background=1"
              width="640"
              height="482"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
            >
            </iframe>
            <i class="border-radius"></i>
          </div>
          <PriceInput
            :onMint='onMint'
            :price='3.3'
            :totalRemaining='this.remainingRon1'
            :minter='1'
            v-bind:showRemains='this.showRemains'
          />
        </div>
      </div>

      <div class="featured-collection reverse">
        <div class="col featured-text">
          <h6 class="headline">NFT Description</h6>
          <p>“I-SOAR” - Edition of 50</p>
          <p>By Astronaut Ron Garan depicts the true nature of the real world. It establishes not only that we are all deeply interconnected—we are also deeply interdependent. I-SOAR declares that we are:</p>
          <p>One People - Traveling on One Planet - Toward One Common Future</p>
          <h6>The title I-SOAR has many meanings including:</h6>
          <ul>
            <li>In order to see this interdependent big picture, we have to soar to new heights—but we do not have to go to space to have this perspective.</li>
          </ul>
          <h6>Webster Dictionary Definition: Soar (verb)</h6>
          <ul>
            <li>To fly upward, as a bird</li>
            <li>To rise or aspire to a higher or more exalted level</li>
            <li>There are some who are subscribing to a new wave of ultra-nationalism and racism that would see this idea of one human family as an “eyesore”. This work flips that word eyesore on its head and reveals the power and beauty of I-SOAR</li>
            <li>I-SOAR stands for the Interrelated Structure of All Reality. It is a quote from Dr. Martin Luther King’s 1967 “Christmas Sermon on Peace”</li>
          </ul>
        </div>
        <div class="col featured-image">
          <div class="featured-artwork">
            <img :src="artRon2" alt="Astronaut Ron Garan Artwork">
            <i class="border-radius"></i>
          </div>
          <PriceInput
            :onMint='onMint'
            :price='0.2'
            :totalRemaining='this.remainingRon2'
            :minter='2'
            v-bind:showRemains='this.showRemains'
          />
        </div>
      </div>

      <div class="featured-collection reverse">
        <div class="col featured-text">
          <h6 class="headline">NFT Description</h6>
          <p>Royal Wedding - Edition of 25</p>
          <p>This NFT is based on an oil on canvas work Astronaut Ron Garan painted in 2014. The painting is based on a photo he took while he lived and worked onboard the International Space Station in 2011. Ron took the photo near sunset on the eve of the wedding between Prince William and Kate Middleton. Northern England and southern Scotland can be seen between the cloud deck. The photo was tweeted on the day of the wedding of Prince William and Kate Middleton, April 29th, 2011.</p>
        </div>
        <div class="col featured-image">
          <div class="featured-artwork is-video">
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/719266060?h=43a45a5818&amp;autoplay=1&amp;autopause=false&amp;loop=1&amp;muted=1&amp;?background=1"
              width="640"
              height="482"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
            >
            </iframe>
            <i class="border-radius"></i>
          </div>
          <PriceInput
            :onMint='onMint'
            :price='0.3'
            :totalRemaining='this.remainingRoyalWedding'
            :minter='4'
            v-bind:showRemains='this.showRemains'
          />
        </div>
      </div>

      <div class="featured-collection reverse">
        <div class="col featured-text">
          <h6 class="headline">NFT Description</h6>
          <p>Earthshine - Edition of 50</p>
          <p>This NFT is based on an oil on canvas work Astronaut Ron Garan painted in 2015. The painting is based on a photo he took (titled “Earthshine”) while Ron lived and worked onboard the International Space Station in 2011.</p>
          <p>Ron’s description, <em>“Spending months in space I can pinpoint specific moments when I wasn't just looking down at the Earth, I was looking at a planet hanging in the blackness of space. "Earthshine" depicts one of those moments. From the vantage point of the windowed observatory known as "The Cupola", installed on the Earth facing side of the International Space Station, I witnessed one of our 16 daily sunsets. On one of those sunsets, I also observed the simultaneous setting of a crescent moon that was also being illuminated by reflected twilight from the Earth. The scene impressed upon me the cosmic dance of the Earth and its Moon that has continued since the dawn of time. The silhouettes of towering thunderstorms on the horizon juxtapose our terrestrial existence with the cosmic backdrop. I remember the awe I felt when I immersed myself with the colors and beauty of this moment in time.”</em></p>
        </div>
        <div class="col featured-image">
          <div class="featured-artwork is-video">
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/719266379?h=bb4ec977b4&amp;autoplay=1&amp;autopause=false&amp;loop=1&amp;muted=1&amp;?background=1"
              width="640"
              height="482"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
            >
            </iframe>
            <i class="border-radius"></i>
          </div>
          <PriceInput
            :onMint='onMint'
            :price='0.2'
            :totalRemaining='this.remainingEarthshine'
            :minter='5'
            v-bind:showRemains='this.showRemains'
          />
        </div>
      </div>

      <div class="featured-collection reverse">
        <div class="col featured-text">
          <h6 class="headline">NFT Description</h6>
          <p>Portal - Edition of 25</p>
          <p>This NFT is based on an oil on canvas artist self-portrait that Astronaut Ron Garan painted in 2021. The painting is based on a photo that was taken of Ron after he arrived back aboard the International Space Station after one of his four spacewalks.</p>
          <p>Ron’s description, <em>“In the work I wanted to capture the emotion of the awe and wonder of one of the most profound human experiences in history, being out in the vacuum of space with nothing but a spacesuit sustaining your life.”</em></p>
        </div>
        <div class="col featured-image">
          <div class="featured-artwork is-video">
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/719266295?h=a325266140&amp;autoplay=1&amp;autopause=false&amp;loop=1&amp;muted=1&amp;?background=1"
              width="640"
              height="482"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
            >
            </iframe>
            <i class="border-radius"></i>
          </div>
          <PriceInput
            :onMint='onMint'
            :price='0.4'
            :totalRemaining='this.remainingPortal'
            :minter='6'
            v-bind:showRemains='this.showRemains'
          />
        </div>
      </div>

      <div class="featured-collection normal" id="ryan-stuit">
        <div class="col featured-text">
          <div class="featured-meta">
            <div class="portrait">
              <img :src="portraitRyan" alt="Ryan Stuit">
            </div>
            <h4>Ryan Stuit</h4>
          </div>
          <p><span>Ryan Stuit</span> is an award-winning filmmaker, artist, and musician with a passion for space. He is half of the space music artist Test  Shot Starfish. Stuit is on a very short list of artists who have had their physical work flown in orbit. His Inspiration4 physical painting and NFT sold as part of the mission fund raise after safely returning to earth. His newest collection of digital art is his genesis collection debuting at Miami Art Week 2021.</p>
          <h6 class="headline">NFT Description</h6>
          <p>“Res.Cue” - Edition of 50</p>
          <p>Digital composite of 3D astronauts converted to vector images with composite mixed media paints and papers. This piece was originally created for an upcoming album release for the project Test Shot Starfish - <a href="https://testshotstarfish.com/" target="_blank" rel="noopener noreferrer">Music for Space</a>.</p>
        </div>
        <div class="col featured-image">
          <div class="featured-artwork">
            <img :src="artRyan" alt="Ryan Artwork">
            <i class="border-radius"></i>
          </div>
          <PriceInput
            :onMint='onMint'
            :price='0.1'
            :totalRemaining='this.remainingRyan'
            :minter='3'
            v-bind:showRemains='this.showRemains'
          />
        </div>
      </div>

      <div class="featured-collection reverse" id="inspiration4">
        <div class="col featured-text">
          <div class="featured-meta">
            <h4>“Endless Inspiration”<br>Inspiration4 For St. Jude</h4>
          </div>
          <p>On September 15, 2021, the crew of Inspiration4 blasted off from NASA’s Kennedy Space Center on a SpaceX Falcon 9 rocket, officially beginning the age of civilian spaceflight.</p>
          <p><span>Endless Inspiration</span> is a dynamic artwork incorporating advanced scientific imagery of this historic mission. Captured by tracking telescopes at NASA's Kennedy Space Center in Florida, this work preserves a unique record of the Inspiration4 space flight from liftoff to orbit while also presenting data of a kind never seen by the public. Created by Mars Scientific.</p>
          <div class="logo-company">
            <img :src="logoStJude" alt="St Jude logo">
            <img :src="logoMarsScientific" alt="Mars Scientific logo">
          </div>
        </div>
        <div class="col featured-image">
          <div class="featured-artwork is-video">
            <iframe src="https://player.vimeo.com/video/649723282?h=4681226995&autoplay=1&autopause=false&loop=1&muted=1&?background=1&player_id=0&app_id=58479" frameborder="0" allowfullscreen title="MARS Scientific Endless Inspiration.mov"></iframe>
            <i class="border-radius"></i>
          </div>
          <br>
          <br>
          <button class="button disabled">Coming soon</button>
        </div>
      </div>

    </div>
    <div class="bg-img" v-bind:style="{ 'background-image': 'url(' + require('../assets/bg-bitbasel.jpg') + ')' }"></div>
  </section>
</template>

<script>
import PriceInput from '@/components/PriceInput.vue';
import Web3 from "web3";
import WL from 'walletlink';
import Portis from '@portis/web3';
import cbSVG from '../assets/coinbase-wallet.svg';
import WalletConnectProvider from "@walletconnect/web3-provider";

export default {
  name: "ArtCollection",
  props: ['ETHwallet'],
  components: {
    PriceInput
  },
  data () {
    return {
      portraitSian: require('@/assets/portrait-sian.jpg'),
      portraitRon: require('@/assets/portrait-ron.jpg'),
      artRon1: require('@/assets/art-ron-1.jpg'),
      artRon2: require('@/assets/art-ron-2.jpg'),
      portraitRyan: require('@/assets/portrait-ryan.jpg'),
      artRyan: require('@/assets/art-ryan.jpg'),
      logoStJude: require('@/assets/logo-st-jude.png'),
      logoMarsScientific: require('@/assets/logo-mars-scientific.png'),
      longText: `“There usually are two ways to define the word perspective. It may refer to the rendering or interpretation of a three-dimensional object on a two-dimensional plane, or it may refer to an attitude toward something, a point of view. Both of these definitions come into play in this piece.<br><br> Historically, for the most part, our perspective has been two-dimensional. Although we know that the world is not flat, a true perspective on a three-dimensional, interrelated reality is usually beyond our immediate awareness.<br><br> This piece was inspired by a photograph I took during a spacewalk on the STS-124 mission. In some respects, this work can be seen as a self-portrait. My reflection is depicted in the helmet visor of my spacewalk partner Astronaut Mike Fossum. In the reflection I can be seen taking the photograph that this work is based on.<br><br> As opposed to establishing a focal point with surrounding areas leading the viewer’s eye to that focal point, in this piece great detail can be found throughout. This was done to communicate the sometimes overwhelming experience of a spacewalk where the participant is bombarded with compelling imagery competing for the viewer’s attention. The detail that is found throughout is also meant to acknowledge and applaud the great detail that must be present during all aspects of a space mission to maximize the chances of a safe mission.<br><br> The setting of the piece occurs moments before passing into orbital night. My goal in the piece was also to contrast the technical and visually overwhelming experience of a spacewalk with the tranquil beauty of our planet as we approach one of our 16 daily sunsets.”`,
      readMoreActivated: false,
      concatenatedAddress: "",
      isWalletConnected: false,
      account: "",
      provider: "",
      remainingSian: '---',
      remainingRon1: '---',
      remainingRon2: '---',
      remainingRyan: '---',
      remainingRon4: '---',
      remainingRon5: '---',
      remainingRon6: '---',
      remainingRoyalWedding: '---',
      remainingEarthshine: '---',
      remainingPortal: '---',
      chainID: 1, 
      contractSian: "0x0BA5d148a38E4D260Ab090eEa90B3330EB8875C8",
      contractRon: "0xF9455eE7EdC40ff17E17c9bb378CEdCD48385eE7", 
      contractRyan: "0x47CE77B0aB2a54508d3b926F85e1c78375c9be69",
      contractRoyalWedding: "0x2EC066699eE993faA814373bF718BB3Bd2eC3570",
      contractEarthshine: "0x42dB27974b7D0d53FE98B18d361620faf5cb2972",
      contractPortal: "0xaeE50cF3aD3c6695Ce1F27D7E699cf860f4Fb16b",
      passerVal: true,
      showRemains: false
    }
  },
  methods: {
    activateReadMore(){
        this.readMoreActivated = true;
    },
    onAccountsChange(accounts) {
      if (accounts.length === 0) {
        this.isWalletConnected = false;
      } else {
        const [account] = accounts;
        const first5 = account.slice(0, 5);
        const last4 = account.slice(account.length - 4, account.length);
        this.concatenatedAddress = `${first5}...${last4}`;
        this.isWalletConnected = true;
        this.account = account;
      }
    },

    /**
     * @param {Object} error
     */
    onWalletConnectError(error) {
      if (error.code === 4001) {
        console.log("User has rejected the connection request.");
      } else {
        console.error(error.message);
      }
    },
    async connectWallet() {
      const chainId = this.chainID;
      const providerOptions = {
          'custom-walletlink': {
              display: {
                  logo: cbSVG,
                  name: 'Coinbase',
                  description: 'Use WalletLink to connect to Coinbase',
              },
              options: {
                  appName: 'Going to Space',
                  networkUrl: 'https://mainnet.infura.io/v3/e42b233fda434c1dbeffe8449d8e2763',
                  chainId,
              },
              package: WL,
              connector: async (_, options) => {
                  const { appName, networkUrl, chainId } = options
                  const walletLink = new WL({
                      appName
                  });
                  const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
                  await provider.enable();
                  return provider;
              },
          },
          portis: {
              package: Portis,
              options: {
                  id: "31c26f24-9748-4029-87d1-76ef5ba39b8a",
                  chainId
              }
          },
          walletconnect: {
            package: WalletConnectProvider, 
            options: {
              infuraId: "0421d5ecf8894580a7bbfc5c1d3be0c9" 
            }
          }

      };
      if (this.passerVal === true){
        const Web3Modal = require('web3modal')
        const web3Modal = new Web3Modal.default({
            theme: "dark",
            network: "mainnet",
            providerOptions
        });
        web3Modal.clearCachedProvider();
        console.log(this.passerVal);
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        this.provider = web3;
      }
      //const user = await Moralis.authenticate({ provider: "walletconnect", chainId: 56 })
      const netId = await this.provider.eth.net.getId()
      const accounts = await this.provider.eth.getAccounts()
      this.account = accounts[0];
      if (netId != this.chainID){
        this.$store.commit('SEND_TOAST_MESSAGE', {
            message: "Please switch network to Ethereum Mainnet",
            type: "error"});
          return this.account;
      }
      this.isWalletConnected = true;
      const abisian = require('../../build/contracts/SianNFTsEdition.json')
      const contractSian = new this.provider.eth.Contract(
        abisian.abi,
        this.contractSian
      );
      const supplySian = await contractSian.methods.remaining().call();

      const abiron = require('../../build/contracts/RonNFTsEdition.json')
      const contractRon = new this.provider.eth.Contract(
        abiron.abi,
        this.contractRon
      );
      const supplyRon = await contractRon.methods.remaining().call();

      const abiRoyalWedding = require('../../build/contracts/RoyalWedding.json')
      const contractRoyalWedding = new this.provider.eth.Contract(
        abiRoyalWedding.abi,
        this.contractRoyalWedding
      );
      const supplyRoyalWedding = await contractRoyalWedding.methods.remaining().call();
      const abiryan = require('../../build/contracts/RyanNFTsEdition.json')
      const contractRyan = new this.provider.eth.Contract(
        abiryan.abi,
        this.contractRyan
      );

      const abiES = require('../../build/contracts/Earthshine.json')
      const contractEarthshine = new this.provider.eth.Contract(
        abiES.abi,
        this.contractEarthshine
      );
      const supplyEarthshine = await contractEarthshine.methods.remaining().call()
      const supplyRyan = await contractRyan.methods.remaining().call();

      const abiPortal = require('../../build/contracts/Portal.json')
      const contractPortal = new this.provider.eth.Contract(
        abiPortal.abi,
        this.contractPortal
      );
      const supplyPortal = await contractPortal.methods.remaining().call()

      this.remainingSian = supplySian;
      this.remainingRon2 = supplyRon;
      this.remainingRyan= supplyRyan;
      this.remainingRoyalWedding = supplyRoyalWedding;
      this.remainingEarthshine = supplyEarthshine
      this.remainingPortal = supplyPortal;

      try{
        await contractRon.methods.tokenURI(0).call();
        this.remainingRon1 = 0;
      }catch (error){
        this.remainingRon1 = 1;
      }
      this.passerVal = false;
      this.showRemains = true;
      return this.account;
    },
    /**
     * Get the total value in ETH and quantity selected.
     */
    async onMint({quantity, totalEth, price, minter}) {
      await this.connectWallet();
      const abisian = require('../../build/contracts/SianNFTsEdition.json')
      const contractSian = new this.provider.eth.Contract(
        abisian.abi,
        this.contractSian
      );
      const supplySian = await contractSian.methods.remaining().call();

      const abiron = require('../../build/contracts/RonNFTsEdition.json')
      const contractRon = new this.provider.eth.Contract(
        abiron.abi,
        this.contractRon
      );
      const supplyRon = await contractRon.methods.remaining().call();

      const abiRoyalWedding = require('../../build/contracts/RoyalWedding.json')
      const contractRoyalWedding = new this.provider.eth.Contract(
        abiRoyalWedding.abi,
        this.contractRoyalWedding
      );
      const supplyRoyalWedding = await contractRoyalWedding.methods.remaining().call();

      const abiES = require('../../build/contracts/Earthshine.json')
      const contractEarthshine = new this.provider.eth.Contract(
        abiES.abi,
        this.contractEarthshine
      );

      const supplyEarthshine = await contractEarthshine.methods.remaining().call();

      const abiPortal = require('../../build/contracts/Portal.json')
      const contractPortal = new this.provider.eth.Contract(
        abiPortal.abi,
        this.contractPortal
      );
      const supplyPortal = await contractPortal.methods.remaining().call();

      const abiryan = require('../../build/contracts/RyanNFTsEdition.json')
      const contractRyan = new this.provider.eth.Contract(
        abiryan.abi,
        this.contractRyan
      );
      const supplyRyan = await contractRyan.methods.remaining().call();

      this.remainingSian = supplySian;
      this.remainingRon2 = supplyRon;
      this.remainingRyan= supplyRyan;
      this.remainingRon4 = supplyRon;
      this.remainingRoyalWedding = supplyRoyalWedding;
      this.remainingEarthshine = supplyEarthshine
      this.remainingPortal =supplyPortal
      try{
        await contractRon.methods.tokenURI(0).call();
        this.remainingRon1 = 0;
      }catch (error){
        this.remainingRon1 = 1;
      }

      console.log('onMint', quantity, totalEth, price, minter)
      var balance = await this.provider.eth.getBalance(this.account);
      balance = this.provider.utils.fromWei(balance,'ether');
      if (Number(balance) < Number(totalEth)){
        this.$store.commit('SEND_TOAST_MESSAGE', {
              message: "You need " + String(Number(totalEth) - Number(balance)).slice(0,6) + " more ETH to make this purchase.",
              type: "error"});
        return;
      }
      if(minter == 0){
        if (this.remainingSian == 0){
          this.$store.commit('SEND_TOAST_MESSAGE', {
              message: "Seeker is SOLD OUT! Purchase on opensea.io",
              type: "info"});
          return
        }
        console.log('Sian')
        await contractSian.methods.mintNFT(quantity).send({from: this.account, value: this.provider.utils.toWei(totalEth, 'ether')})
      }
      else if(minter == 1){
        if (this.remainingRon1 == 0){
          this.$store.commit('SEND_TOAST_MESSAGE', {
              message: "Perspective has sold already! Purchase on opensea.io",
              type: "info"});
          return
        }
        console.log('ron 1 of 1')
        await contractRon.methods.mint1of1().send({from: this.account, value: this.provider.utils.toWei(totalEth, 'ether')})
      }
      else if(minter == 2){
        if (this.remainingRon2 == 0){
          this.$store.commit('SEND_TOAST_MESSAGE', {
              message: "I-SOAR is SOLD OUT! Purchase on opensea.io",
              type: "info"});
          return
        }
        console.log('ron edition')
        await contractRon.methods.mintNFT(quantity).send({from: this.account, value: this.provider.utils.toWei(totalEth, 'ether')})
      }
      else if(minter == 3){
        if (this.remainingRyan == 0){
          this.$store.commit('SEND_TOAST_MESSAGE', {
              message: "Res.Cue is SOLD OUT! Purchase on opensea.io",
              type: "info"});
          return
        }
        console.log('ryan')
        await contractRyan.methods.mintNFT(quantity).send({from: this.account, value: this.provider.utils.toWei(totalEth, 'ether')})
      }
       else if(minter == 4){
        if (this.remainingRoyalWedding == 0){
          this.$store.commit('SEND_TOAST_MESSAGE', {
              message: "Royal Wedding is SOLD OUT! Purchase on opensea.io",
              type: "info"});
          return
        }
        console.log('ron edition')
        await contractRoyalWedding.methods.mintNFT(quantity).send({from: this.account, value: this.provider.utils.toWei(totalEth, 'ether')})
      }
      else if(minter == 5){
        if (this.remainingEarthshine == 0){
          this.$store.commit('SEND_TOAST_MESSAGE', {
              message: "Earthshine is SOLD OUT! Purchase on opensea.io",
              type: "info"});
          return
        }
        console.log('ron edition')
        await contractEarthshine.methods.mintNFT(quantity).send({from: this.account, value: this.provider.utils.toWei(totalEth, 'ether')})
      }
      else if(minter == 6){
        if (this.remainingPortal == 0){
          this.$store.commit('SEND_TOAST_MESSAGE', {
              message: "Portal is SOLD OUT! Purchase on opensea.io",
              type: "info"});
          return
        }
        console.log('ron edition')
        await contractPortal.methods.mintNFT(quantity).send({from: this.account, value: this.provider.utils.toWei(totalEth, 'ether')})
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/mixins.scss';

.bitbasel-collection {
  padding: 80px 0;

  @include breakpoint(mobile) {
    padding: 60px 0;
  }

  .bg-img {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    opacity: 0.4;
  }
}

.featured-collection {
  margin: 0 0 120px;
  display: flex;
  flex-flow: row nowrap;
  @include breakpoint(tablet) {
    margin-bottom: 80px;
    flex-flow: column wrap;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .border-radius {
    width: 100%;
    height: inherit;
    top: 10px;
    position: absolute;
    border: 2px solid #6A00FF;
    border-radius: 8px;
    z-index: 1;
    @include breakpoint(tablet) {
      border-width: 1px;
      top: 3px;
    }
  }

  &.normal {
    .border-radius {
      left: -10px;
      @include breakpoint(tablet) {
        left: -3px;
      }
    }
    
    .col {
      &:first-child {
        margin-right: 80px;
        @include breakpoint(tablet) {
          margin-right: initial;
        }
      }

      &:last-child {
        margin-left: 20px;
        @include breakpoint(tablet) {
          margin-left: initial;
        }
      }
    }
  }

  &.reverse {
    flex-flow: row-reverse nowrap;
    @include breakpoint(tablet) {
      flex-flow: column wrap;
    }

    .border-radius {
      right: -10px;
      @include breakpoint(mobile) {
        right: initial; left: -3px;
      }
    }

    .col {
      &:first-child {
        margin-left: 20px;
        @include breakpoint(tablet) {
          margin-left: initial;
        }
      }

      &:last-child {
        margin-right: 80px;
        @include breakpoint(tablet) {
          margin-right: initial;
        }
      }
    }
  }
}

.featured-collection {
  .featured-text {
    width: 50%;
    @include breakpoint(tablet) {
      width: 100%;
      margin-bottom: 40px;
    }

    .featured-meta {
      width: 100%;
      margin: 0 0 24px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      h4 {
        margin: 0;
      }
    }

    .portrait {
      width: 72px;
      height: 72px;
      margin: 0 16px 0 0;

      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
        object-position: 50% 50%;
        border-radius: 50px;
      }
    }
  }

  .featured-image {
    width: 50%;
    @include breakpoint(tablet) {
      width: 100%;
    }
  
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
      border-radius: 8px;
      position: relative;
      background-color: #000000;
      z-index: 4;
      @include breakpoint(mobile) {
        object-fit: cover;
      }
    }
  }
}

.featured-collection {
  .featured-artwork {
    width: 100%;
    height: 440px;
    position: relative;
    z-index: 4;
    @include breakpoint(tablet) {
      height: 460px;
    }
    @include breakpoint(mobile) {
      height: 300px;
    }

    iframe {
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      background-color: #000000;
      border-radius: 8px;
      z-index: 4;
    }

    &.is-video {
      height: 480px;
      @include breakpoint(tablet) {
        height: 320px;
      }
      @include breakpoint(mobile) {
        height: 300px;
      }
    }
  }
}

.featured-collection {
  &#ryan-stuit {
    .featured-artwork {
      height: 660px;
      @include breakpoint(laptop) {
        height: 440px;
      }
    }
  }
}

.logo-company {
  width: 120px;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 0 50%;
    border-radius: 8px;

    &:not(:first-child) {
      margin-left: 80px;
    }
  }
}
</style>