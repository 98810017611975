<template>
  <div class="price-input">
    <div class="price-stepper price-info">
      <button
        @click='updateQuantity'
        data-operation='subtract'
        class="price-stepper-btn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g
            transform="translate(0.5 0.5)"
          >
            <line x1="17" y1="12" x2="7" y2="12"/>
          </g>
        </svg>
      </button>
      <label class="headline">Quantity</label>
      <input
        type="number"
        v-model="quantity"
        readonly
      />
      <button
        @click='updateQuantity'
        data-operation='add'
        class="price-stepper-btn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g
            transform="translate(0.5 0.5)"
          >
            <line x1="12" y1="7" x2="12" y2="17"/>
            <line x1="17" y1="12" x2="7" y2="12"/>
          </g>
        </svg>
      </button>
    </div>
    <div class="price-eth price-info">
     <span class="headline">Each</span>
     <span>{{ price }} ETH <EthLogo /></span>
    </div>
    <div v-if="showRemains" class="price-remaining price-info">
      <span class="headline">Total Remaining</span>
      <strong>{{ totalRemaining }}</strong>
    </div>
    <button
      @click="onMint"
      class="button price-button">
      Mint
    </button>
    <div class="price-total">
      <strong>{{ totalEth }}</strong>
      ETH
    </div>
  </div>
</template>

<script>
import EthLogo from '@/components/EthLogo.vue';

export default {
  name: "PriceInput",
  props: {
    price: {
      type: Number,
      default: 0.1
    },
    totalRemaining: {
      type: String,
      default: '---'
    },
    minter: {
      type: Number,
      default: 10
    },
    showRemains : {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalEth() {
      return (this.price * this.quantity).toFixed(3)
    }
  },
  data(){
    return {
      quantity: 1
    }
  },
  components: {
    EthLogo
  },
  methods: {
    updateQuantity(event) {
      const {operation} = event.target.dataset;
      const isMaxBounds = (this.quantity === Number(this.totalRemaining));
      const maxPerTx = 30;

      if (operation === 'add') {
        if(this.minter == 1){
          return
        }
        this.quantity = (this.quantity === maxPerTx) ? maxPerTx : isMaxBounds ? this.quantity : this.quantity + 1;
      } else {
        this.quantity = this.quantity <= 1 ? 1 : this.quantity - 1;
      }
    },
    async onMint() {
      console.log(this.minter, this.price)
      this.$emit('mint', {
        quantity: this.quantity,
        totalEth: this.totalEth,
        price: this.price,
        minter: this.minter
      });
    }
  }
};
</script>

<style scoped lang='scss'>

@import '../styles/mixins';

$mint: #36DDB9;
$purple: #6A00FF;
$bg: hsl(249deg, 29%, 20%);

.price-input {
  --input-h: 80px;
  margin: 60px 0;
  display: grid;
  grid-gap: 8px 16px;
  grid-template-columns: auto 1fr 1fr;
  color: white;
  align-items: center;
  height: var(--input-h);

  @include breakpoint(mobile) {
    margin: 16px 0;
    grid-template-columns: 1fr 1fr;
    height: auto;
    grid-gap: 8px;
  }
}

.price-info {
  background: $bg;
  position: relative;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  height: var(--input-h);
  @include breakpoint(mobile) {
    align-items: flex-end;
    padding: 16px 16px;
  }
}

.headline {
  flex: 1;
  position: relative;
  color: $mint;
  font-weight: 700;
  position: absolute;
  top: -8px;
  left: 0;
  transform: translate3d(0, -100%, 0);
  font-size: smaller;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  @include breakpoint(mobile) {
    top: initial;
    left: 0;
    right: 0;
    top: 12px;
    margin: auto;
    text-align: center;
    transform: none;
    font-size: 10px;
  }
}

.price-stepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  input {
    font-variant-numeric: tabular-nums;
    font-family: 'industry', sans-serif;
    max-width: 64px;
    height: var(--input-h);
    background: transparent;
    color: white;
    padding: 0 24px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid transparent;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    left: 8px;
    @include breakpoint(mobile) {
      left: initial;
      height: initial;
      top: 12px;
    }
  }
  @include breakpoint(mobile) {
    grid-column: span 2;
  }
}

.price-eth {
  display: flex;
  font-weight: 600;
  span:not(.headline) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include breakpoint(mobile) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-evenly;
    }
  }
}

.price-stepper-btn {
  outline: none;
  border: none;
  padding: 0 16px;
  cursor: pointer;
  height: var(--input-h);
  background: lighten($bg, 3.5%);
  &:hover {
    background: lighten($bg, 7.5%);
  }

  > * {
    pointer-events: none;
  }

  &[data-operation='add'] {
    border-radius: 0 8px 8px 0;
    right: 0;
  }
  &[data-operation='subtract'] {
    border-radius: 8px 0 0 8px;
    left: 0;
  }

  svg {
    fill: none;
    stroke: white;
    stroke-width: 1;
    stroke-linecap: square;
    stroke-linejoin: miter;
    stroke-miterlimit: 10;
  }
  &:hover svg {
    stroke: $mint;
  }
}

.price-button {
  margin: 0;
  cursor: pointer;
  outline: none;
}

.price-remaining {
  @include breakpoint(mobile) {
    justify-content: center;
  }
}

.price-total {
  font-variant-numeric: tabular-nums;
  margin-left: 8px;
}

</style>
