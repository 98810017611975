/**
 * Returns a random float (with decimals) number between min and max
 * @param {Number} min Minimum number
 * @param {Number} max Maximum number
 * @return {Number} Float number
 */
 export function getRandomFloat (min, max) {
  return Math.random() * (max - min) + min;
}

/**
* Returns a random integer between min and max
* @param {Number} min Minimum number
* @param {Number} max Maximum number
* @return {Number} Integer number
*/
export function getRandomInt (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
* @param {String} name
* @returns {String}
*/
export function getRandomId (name = '') {
  return Math.round(Math.random() * 10E15).toString(32) + '-' + name;
}
