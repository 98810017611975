<template>
  <section class="hero">
    <div class="container">
      <h1>Going to <span>Space</span></h1>
      <div class="text-block">
        <h6 class="headline">NFT Collection</h6>
        <p>Welcome to Going to Space, celebrating the science, exploration, and settlement in space.</p>
        <h6 class="headline">Discover &amp; Collect</h6>
        <p>Our NFTs are powered by the global space community from astronauts, engineers, scientists, and artists, Going to Space publishes collections that excite and inspire the space community worldwide.</p>
      </div>
    </div>
    <div class="bg-img" v-bind:style="{ 'background-image': 'url(' + require('../assets/astronaut-mars.jpg') + ')' }"></div>
  </section>
</template>


<script>

export default {
  setup() {
    
  },
}
</script>

<style lang="scss">
@import '../styles/mixins.scss';

.hero {
  padding: 140px 0 80px;

  @include breakpoint(laptop) {
    padding: 120px 0 56px;
  }

  &:before {
    content: '';
    width: 70%;
    height: 100%;
    top: 0; right: 0; bottom: 0; left: 0;
    position: absolute;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    z-index: 2;
  }
}

.hero {
  h1 span {
    margin-left: 200px;
    display: block;

    @include breakpoint(mobile) {
      margin-left: 0px;
    }
  }
}

.hero .text-block {
  width: 500px;

  @include breakpoint(laptop) {
    width: 50%;
  }

  @include breakpoint(tablet) {
    width: 100%;
  }

  p {
    margin: 0 0 48px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

</style>
