<template>
  <footer>
    <div class="container">
      <div class="col col-left">
        <a href="#" class="logo">
          <svg viewBox="0 0 450 170" data-v-303de3fa=""><g data-v-303de3fa=""><path d="M226,127.1c-12.9,0-22.1-4.4-28.9-11.3c-6.1-6.4-9.8-15.1-9.8-24.8c0-8.9,3.1-16.7,8.2-23c6.7-8.1,16.7-13.1,30.5-13.1 h38.7v15.7H225c-7.7,0-13.1,3-16.9,7.4c-3,3.7-4.8,8.1-4.8,13.1c0,5,1.9,9.6,5,13.4c3.7,4.3,9.1,7.4,16.7,7.4h22.5 c1.1,0,1.6-0.5,1.6-1.7V87.6h15.8v35c0,3.1-1.5,4.6-4.6,4.6H226z" data-v-303de3fa=""></path> <path d="M355.5,54.9v15.7h-33.4v56.6h-16V70.6h-33.5V54.9H355.5z" data-v-303de3fa=""></path> <path d="M439.3,70.6H385c-3.1,0-4.7,0.7-5.9,2c-1.3,1.4-1.9,3-1.9,4.8c0,1.7,0.5,3.2,1.7,4.5c1.2,1.3,2.9,2,6,2h33.7 c9.1,0,15.2,3,19.1,7.8c3.1,3.7,5,8.6,5,13.8c0,5-1.8,9.6-4.8,13.5c-3.8,4.8-9.8,8.1-19.3,8.1h-55.5v-15.3h55.1 c3.7,0,5.6-0.9,6.7-2.1c1.2-1.4,1.8-2.9,1.8-4.7c0-1.8-0.6-3.4-2-4.8c-1.2-1.3-2.8-2-6.5-2H385c-9.7,0-14.9-2.5-18.4-6.2 c-3.7-4.2-5.5-9.7-5.5-15.4c0-5.6,1.7-10.8,5-14.6c3.7-4.4,9.1-7.1,18.8-7.1h54.3V70.6z" data-v-303de3fa=""></path></g> <path d="M146.4,85.5c-0.1,16-6.3,31.9-18.4,44.1c-24.6,24.6-64.5,24.6-89.1,0c-24.6-24.6-24.6-64.5,0-89.1 c11.2-11.2,25.4-17.2,40-18.3c-19.6,4.4-32.6,21.9-30.7,44.1c2.3,27.2,26.3,51.1,53.5,53.5C125.1,121.8,143.3,107.1,146.4,85.5z" data-v-303de3fa=""></path> <path d="M64.6,141.3c-29.2,22.1-53.7,32.5-61.9,24.3c-8.2-8.2,2.2-32.7,24.3-61.9c-9.4,18.2-11.4,33.8-3.8,41.4 C30.9,152.7,46.5,150.7,64.6,141.3z" data-v-303de3fa=""></path> <path d="M164,4.4c8.2,8.2-2.2,32.7-24.3,61.9c9.4-18.2,11.4-33.8,3.8-41.4c-7.6-7.6-23.2-5.6-41.4,3.8C131.3,6.6,155.8-3.8,164,4.4 z" data-v-303de3fa=""></path></svg>
        </a>
      </div>

      <div class="col col-right">
        <div class="footer-links">
          <h6 class="headline">Links</h6>
          <ul>
            <li>
              <a href="https://goingtospace.com/" target="_blank" rel="noopener noreferrer">Going to Space</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer-copyright">
        <p>Copyright © 2022 Going to Space. All rights reserved.</p>
        <ul>
          <li>
            <a href="https://goingtospace.com/privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
          </li>
          <li>
            <a href="https://goingtospace.com/privacy" target="_blank" rel="noopener noreferrer">Terms of Service</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
@import '../styles/mixins.scss';

footer {
  width: 100%;
  padding: 64px 0 32px;
  background-color: #0f121f;

  @include breakpoint(tablet) {
    padding: 40px 0 32px;
  }

  .logo {
    svg {
      width: auto;
    }
  }

  .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @include breakpoint(tablet) {
      flex-flow: column wrap;
    }
  }

  .col {
    width: 50%;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style:none;
  }

  .footer-links {
    h6.headline {
      margin: 0 0 16px;
      text-align: right;

      @include breakpoint(tablet) {
        margin-top: 40px;
        text-align: left;
      }
    }

    li {
      text-align: right;

      @include breakpoint(tablet) {
        text-align: left;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      color: #686F8D;
      font-size: 16px;
      text-decoration: none;
    }
  }

  .footer-copyright {
    width: 100%;
    margin: 30px 0 0;
    padding: 30px 0 0;
    border-top: 1px solid #1f212a;

    @include breakpoint(tablet) {
      margin: 30px 0 0;
      padding: 30px 0 0;
    }

    p {
      color: #686F8D;
      font-size: 16px;
      margin: 0 0 16px;

      @include breakpoint(tablet) {
        margin-bottom: 16px;
      }
    }

    li {
      margin-bottom: 0;
      display: inline-block;

      &:not(:last-of-type) {
        margin-right: 16px;
      }

      a {
        color: #686F8D;
        font-size: 16px;
        text-decoration: none;   
      } 
    }
  }
}
</style>