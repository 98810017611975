<template>
  <div
    class='toast-message'
    v-if='toastMessages.length > 0'
  >
    <div
      class='toast'
      v-for='(toast) in toastMessages'
      :key='toast.id'
      :data-id='toast.id'
      :data-message='toast.message'
      @click='dismissMessage'
      :class='{ [toast.type]: true }'
    >
      <div
        class='toast-icon'
        :class='{ [toast.type]: true }'
      >
        <AppIcon :name='toast.type'/>
      </div>
      <div class='toast-text'>{{toast.message}}</div>
      <div class='toast-dismiss toast-icon'>
        <AppIcon name='close'/>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from '@/components/AppIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'toast-message',
  components: {
    AppIcon
  },
  data() {
    return {
      delayToDismiss: 7
    }
  },
  computed: {
    ...mapGetters({
      toastMessages: 'toastMessages'
    })
  },
  watch: {
    toastMessages: {
      deep: true,
      handler(val) {
        if (val) {
          const [toast] = val;
          const id = toast && toast.id;
          if (id) {
            this.timeoutMessage(id);
          }
        }
      }
    }
  },
  methods: {
    dismissMessage(event) {
      const target = event.target;
      const parent = target.parentElement;
      const { message, id } = parent.dataset;
      parent.addEventListener('transitionend', () => {
        this.$store.commit('DELETE_TOAST_MESSAGE', { message, id });
        if (parent.dataset.id === id) {
          parent.remove();
        }
      });
      parent.classList.add('dismiss');
    },
    timeoutMessage(id) {
      if (id) {
        setTimeout(() => {
          Array.from(this.$el.children).forEach(toast => {
            if (toast.dataset.id === id) {
              const dismiss = toast.querySelector('.toast-dismiss');
              if (dismiss) (dismiss).click();
            }
          });
        }, this.delayToDismiss * 1000);
      }
    }
  }
}
</script>

<style lang='scss'>

@use "sass:math";
@import "../styles/vars";
@import "../styles/mixins";

.toast-message {
  width: auto;
  position: fixed;
  top: 16px;
  right: 16px;
  margin: auto;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9001;
  user-select: none;
}

.toast-message .toast {
  --border-w: 4px;
  --depth: 8px;

  @include extrude(desaturate(darken($color-white, 5%), 5%), 1px, 5px, false);

  color: $color-black;
  background: $color-white;
  position: relative;
  overflow: hidden;
  transition: transform 1s ease-out;
  margin-bottom: 1px;
  display: grid;
  grid-template-columns: 56px auto 56px;
  pointer-events: all;
  cursor: pointer;
  transition: all 0.3s ease-in;

  @include media(3-up) {
    min-width: 420px;
    top: 0;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }
  &:last-child {
    border-radius: 0 0 4px 4px;
  }
  &:only-child {
    border-radius: 4px;
  }
  > div {
    display: flex;
    align-items: center;
  }
  .app-icon {
    width: 20px;
    height: 20px;
  }
}

.toast-message .toast-text {
  padding: 24px 16px;
  font-weight: 700;
  .icon {
    pointer-events: none;
  }
}

.toast-message .toast-icon {
  justify-content: center;
  width: 100%;
  justify-self: flex-end;
  cursor: pointer;
}

.toast-message .toast-dismiss * {
  pointer-events: none;
}

.toast-message .dismiss.toast {
  transition:
    transform 0.4s ease-in,
    opacity 0.3s ease-in;
  transform: translate3d(0, 150%, 0);
  opacity: 0;
}

// Style the color for the type of toast message
.toast-message .toast {

  &.info {
    $color-text: darken($color-blue, 50%);
    $color-bg: saturate(darken($color-blue, 0%), 20%);

    background: $color-bg;
    color: $color-text;
    @include extrude(desaturate(darken($color-bg, 5%), 5%), 1px, 5px, false);
    .app-icon {
      stroke: $color-text;
      fill: $color-text;
    }
    .toast-icon:first-child {
      background: lighten($color-bg, 10%);
      .app-icon {
        stroke: lighten($color-text, 20%);
        fill: lighten($color-text, 20%);
      }
    }
  }
  &.error {
    $color-text: darken($color-red, 34%);
    $color-bg: saturate(lighten($color-red, 12%), 0%);

    background: $color-bg;
    color: $color-text;
    @include extrude(desaturate(darken($color-bg, 5%), 5%), 1px, 5px, false);
    .app-icon {
      stroke: $color-text;
      fill: $color-text;
    }
    .toast-icon:first-child {
      background: lighten($color-bg, 10%);
      .app-icon {
        stroke: lighten($color-text, 20%);
        fill: lighten($color-text, 20%);
      }
    }
  }
  &.warning {
    $color-text: saturate(darken($color-beige, 74%), 50%);
    $color-bg: saturate(darken($color-beige, 30%), 80%);

    background: $color-bg;
    color: $color-text;
    @include extrude(desaturate(darken($color-bg, 5%), 5%), 1px, 5px, false);
    .app-icon {
      stroke: $color-text;
      fill: $color-text;
    }
    .toast-icon:first-child {
      background: lighten($color-bg, 10%);
      .app-icon {
        stroke: lighten($color-text, 20%);
        fill: lighten($color-text, 20%);
      }
    }
  }
}

</style>